<template>
  <div class="error">
    <div style="text-align: center">
      <van-icon name="clear" size="104px" color="#F25542"/>
      <p class="text">这是一个错误</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "error",
  //组件引入
  components: {},
  //父子组件传值
  props: {},
  //计算属性，可以缓存，避免每次计算都要重新计算
  computed: {},
  //filters 过滤器
  filters: {},
  //数据
  data() {
    return {}
  },
  //在模板渲染成html前调用,即通常初始化某些属性值,然后再渲染成视图。
  created() {
  },
  //在模板渲染成html后调用，通常是初始化页面完成后，再对html的dom节点进行一些需要的操作。
  mounted() {
  },
  //方法 处理逻辑
  methods: {}
}
</script>

<style scoped>
.error {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  font-size: 20px;
  font-weight: 400;
}

</style>
